<template>
  <page-container title="自检日志列表" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="设备类型" prop="checkstatus">
              <a-select v-model="queryParams.checkstatus">
                <a-select-option value="">请选择</a-select-option>
                <a-select-option v-for="(item, index) in checkstatusOptions" :key="index" :value="item.value">{{item.text}}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="时间范围" prop="starttime">
              <a-range-picker v-model="timerange">
              </a-range-picker>
            </a-form-model-item>
            <div class="query-btns">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="设备注册代码" prop="devicenum">
              <a-input v-model.trim="queryParams.devicenum" placeholder="请输入"></a-input>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">自检日志列表</h3>
          <div class="table-btns">
            <!-- <a-button type="primary" @click="showModal('add')"><a-icon type="plus"></a-icon>新建</a-button> -->
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="devicecheckid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="checkstatus" slot-scope="value">
            <a-tag :color="checkstatusColorMap[value]||''">{{checkstatusMap[value]}}</a-tag>
          </span>
          <span slot="checktime" slot-scope="value" :title="value?moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'):''">{{value?moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss'):''}}</span>
          <span slot="operation" slot-scope="value, record, index">
            <a-button type="link" size="small" @click="deleteConfirm(value, record, index)">删除</a-button>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
  </page-container>
</template>
<script>
import moment from 'moment'
import pagination from '@/mixins/pagination'
import {checkstatusOptions, checkstatusColorMap} from '@/json/ai'
import { getDevicecheckListByCondition, deleteDevicecheck } from 'A/ai.js'
import callCenter from "C/modals/callCenter";
export default {
  mixins: [pagination],
  data() {
    return {
      moment,
      checkstatusOptions,
      checkstatusColorMap,
      showAdvanced: false,
      breadcrumb: [
        {
          name: 'AI+物联网监测',
          path: ''
        },
        {
          name: '监测设备管理',
          path: ''
        },
        {
          name: '设备自检',
          path: ''
        },
      ],
      queryParams: {
        devicenum: '',
        checkstatus: '',
        starttime: '',
        endtime: '',
      },
      timerange: null,
      tableColumns: [
        {
          title: '设备注册代码',
          dataIndex: 'devicenum',
          key: 'devicenum',
          ellipsis: true,
        },
        {
          title: '设备名称',
          dataIndex: 'devicename',
          key: 'devicename',
          ellipsis: true,
        },
        {
          title: '自检状态',
          dataIndex: 'checkstatus',
          key: 'checkstatus',
          scopedSlots: { customRender: 'checkstatus' }
        },
        {
          title: '自检时间',
          dataIndex: 'checktime',
          key: 'checktime',
          scopedSlots: { customRender: 'checktime' }
        },
        {
          title: '操作',
          key: 'operation',
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      counts:'',
    }
  },
  computed: {
    checkstatusMap() {
      let result = {};
      checkstatusOptions.forEach(item => {
        result[item.value] = item.text;
      })
      return result;
    }
  },
  watch: {
    timerange(val) {
      if(val && val.length) {
        this.queryParams.starttime = moment(val[0]).format('YYYYMMDD');
        this.queryParams.endtime = moment(val[1]).format('YYYYMMDD');
      }else {
        this.queryParams.starttime = '';
        this.queryParams.endtime = '';
      }
    }
  },
  created() {
    this.getTableData();
  },
  methods: {
    resetQueryParams() {
      this.timerange = null;
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      // if(params.checkstatus==''&&params.devicenum==''&&params.starttime==''&&params.endtime==''){
      //   getDevicecheckListByCondition(params).then(res => {
      //     this.tableLoading = false;
      //     if(res && res.returncode == '0') {
      //       this.tableData = res.item;
      //       this.counts = res.count;
      //     }
      //   }).catch(err => {
      //     this.tableLoading = false;
      //   })
      // }
      getDevicecheckListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    deleteConfirm(value, record) {
      this.$confirm({
        // title: '',
        content: '确定要删除该数据?',
        okText: '确定',
        cancelText: '取消',
        onOk:() => {
          this.delete(record.devicecheckid);
        },
        onCancel() {},
      });
    },
    delete(devicecheckid) {
      this.showLoading();
      if(devicecheckid) {
        let params = {
          devicecheckid
        };
        deleteDevicecheck(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    showModal(type, value, record) {
      this.modalShowType = type;
      if(type == 'add') {
        this.modalDetailData = null;
      }else {
        this.modalDetailData = record;
      }
      this.modalVisible = true;
    },
  }
}
</script>